import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isMobile = (/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i).test(navigator.userAgent);
  
  constructor() {}
}
